<template>
    <v-container>
        <h1 class="main-heading">
            Results for... <span class="error--text">{{ results }}</span>
        </h1>
        <base-card card-height="630">
            <template v-if="getSearchResultsStatus_Pending" #content>
                <loading-spinner></loading-spinner>
            </template>
            <template v-else-if="search.length == 0" #content>
                <div class="d-flex align-center flex-column">
                    <h1 class="main-heading">
                        No results based on your search query..
                    </h1>
                    <p class="error--text mb-5">You may try another search</p>
                    <v-img
                        src="../assets/noData.svg"
                        width="250px"
                        height="250px"
                        contain
                    >
                    </v-img>
                </div>
            </template>
            <template v-else #content>
                <div
                    v-for="(item, index) in search"
                    :key="index"
                    class="card-position"
                >
                    <h4 class="primary--text capitalize">
                        {{ item.title }}
                    </h4>
                    <!-- <p class="font-weight-light">{{ item.redirect_url }}</p> -->
                    <v-btn
                        outlined
                        style="border: none"
                        color="purple"
                        class="button-position"
                        :to="item.redirect_url"
                    >
                        More Details
                    </v-btn>
                    <v-divider class="my-3"></v-divider>
                </div>
            </template>
        </base-card>
    </v-container>
</template>

<script>
import BaseCard from "../components/base/BaseCard.vue";
import { apiStatus } from "@/api/constants/apiStatus";
import { withAsync } from "@/helpers/withAsync";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { fetchSearch } from "@/api/SearchApi.js";
import LoadingSpinner from "../components/LoadingSpinner.vue";

export default {
    name: "Search",
    props: {
        results: {
            type: String,
            required: false,
            default: "",
        },
    },
    components: {
        BaseCard,
        LoadingSpinner,
    },
    data() {
        return {
            getSearchResultsStatus: apiStatus.Idle,
            search: [],
        };
    },
    computed: {
        ...apiStatusComputed("getSearchResultsStatus"),
    },
    methods: {
        async getSearchResults() {
            this.getSearchResultsStatus = apiStatus.Pending;

            let payload = this.results;

            const { response, error } = await withAsync(fetchSearch, payload);

            if (error) {
                this.getSearchResultsStatus = apiStatus.Error;
                return;
            }

            setTimeout(() => {
                this.getSearchResultsStatus = apiStatus.Success;
            }, 300);

            this.search = response.data.data;
        },
    },
    watch: {
        results(newlVal) {
            this.getSearchResults();
        },
    },
    created() {
        this.getSearchResults();
    },
};
</script>

<style scoped>
.capitalize {
    text-transform: capitalize;
}
.card-position {
    position: relative;
}
.button-position {
    position: absolute;
    top: 0;
    right: 0;
    margin-bottom: 0.5rem;
}
.button-position:hover {
    color: #20262e !important;
    text-decoration: underline;
}
</style>
