import { api } from "./api";


const URLS = {
    search: "portal-search?search",
};

export const fetchSearch = (payload) => {
    return api.get(`${URLS.search}=${payload}`);
};
